<template>
  <section
    :class="$customSectionClasses(props.cfgs)"
    class="object-map__root"
  >
    <div class="container">
      <div
        v-if="values?.title"
        class="h2"
      >
        {{ values?.title }}
      </div>
    </div>
    <div class="object-map__wrapper">
      <div class="container">
        <div class="object-map">
          <div class="object-map-left ">
            <div class="title">Проекты:</div>
            <div class="project-cards-scroll ">
              <div class="project-cards scroll-customize">
                <ProjectCard
                  v-for="project in projectsList"
                  :key="project.id"
                  :project="project"
                  :active-project="activeProject"
                  @changeActiveProject="changeActiveProject"
                />
              </div>
            </div>
          </div>
          <div class="object-map-right">
            <div class="title">Офис продаж:</div>
            <div class="office-card">
              <ObjectMapOfficeCard
                :office-schedule="scheduleList"
                :office-info="officeInfoList"
              />
              <!--              <OfficeCard-->
              <!--                :office-schedule="scheduleList"-->
              <!--                :office-info="officeInfoList"-->
              <!--              />-->
            </div>
            <Map
              v-if="officePoints && officePoints.length"
              :office-points="officePoints"
              :projects="projectsList || []"
              :active-project="activeProject"
              @changeActiveProject="changeActiveProject"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import { catalogStore as useCatalogStore } from '#sitis/stores/modules/catalog-store';
import { appConfigStore as useAppConfigStore } from '#sitis/stores/modules/app-store';
const { $api } = useNuxtApp();
import ProjectCard from '~/components/map/ProjectCard.vue';
import {errorParserServerResponse, ref, useAsset} from '#imports';
import type { ComputedRef, Ref } from '#imports';
// import OfficeCard from '~/components/form-offices-block/OfficeCard.vue';
import type { OfficeInfo, OfficeSchedule } from '~/components/form-offices-block/OfficeCard.vue';
import type { MapPoint } from '~/components/map/Map.vue';
import ObjectMapOfficeCard from '~/cms/blocks/objects-map-block/ObjectMapOfficeCard.vue';

type ObjectMapBlockProps = Omit<IBlockDefaultProps, 'values' | 'cfgs'> & {
	values: {
		title?: string;
	};
	cfgs: SectionTypes;
}

const props = defineProps<ObjectMapBlockProps>();

const { $customSectionClasses } = useNuxtApp();
const { yandexMapApiKey } = useRuntimeConfig().public;

const catalogStore = useCatalogStore();
const appConfigStore = useAppConfigStore();

const {
  catalogTree
} = storeToRefs(catalogStore);

const {
  vars,
  config
} = storeToRefs(appConfigStore);

const projectsList = computed(() => catalogTree?.value[0]?.items);

const officePoints: Ref<MapPoint[]> = ref([]);
const activeProject: Ref<{ id: string | number }> = ref({ id: '' });

const officeInfoList: ComputedRef<OfficeInfo[]> = computed(() => {
  const list = [];

  const address = `<span class="address">${vars.value['Адрес офиса продаж']}</span>` || '';
  if (address) {
    list.push({
      label: 'Адрес:',
      value: address,
      link: ''
    });
  }

  const email = `${vars.value['Email офиса продаж']}` || '';
  if (email) {
    list.push({
      label: 'Эл. почта:',
      value: `<span class="email">${email}</span>`,
      link: `mailto:${email}`,
      img: useAsset('img/svg/object-map/email.svg')
    });
  }

  const email2 = vars.value['Email-2 офиса продаж'] || '';
  if (email2) {
    list.push({
      label: 'Эл. почта:',
      value: `<span class="email">${email2}</span>`,
      link: `mailto:${email2}`,
      img: useAsset('img/svg/object-map/email.svg')
    });
  }

  const phone = vars.value['Телефон офиса продаж'] || '';
  if (phone) {
    list.push({
      label: 'Контактный телефон:',
      value: `<span class="phone">${phone}</span>`,
      link: `tel:${phone}`,
      img: useAsset('img/svg/object-map/phone.svg')
    });
  }

  const phone2 = vars.value['Телефон-2 офиса продаж'] || '';
  if (phone2) {
    list.push({
      label: 'Контактный телефон:',
      value: `<span class="phone">${phone2}</span>`,
      link: `tel:${phone2}`,
      img: useAsset('img/svg/object-map/phone.svg')
    });
  }

  return list;
});
const scheduleList: ComputedRef<OfficeSchedule[]> = computed(() => [
  {
    label: 'Пн',
    workTime: vars.value['График работы (понедельник)'],
    day: 1
  },
  {
    label: 'Вт',
    workTime: vars.value['График работы (вторник)'],
    day: 2
  },
  {
    label: 'Ср',
    workTime: vars.value['График работы (среда)'],
    day: 3
  },
  {
    label: 'Чт',
    workTime: vars.value['График работы (четверг)'],
    day: 4
  },
  {
    label: 'Пт',
    workTime: vars.value['График работы (пятница)'],
    day: 5
  },
  {
    label: 'Сб',
    workTime: vars.value['График работы (суббота)'],
    day: 6
  },
  {
    label: 'Вс',
    workTime: vars.value['График работы (воскресение)'],
    day: 7
  }
]);

const getSalesOfficePoint = async (addressOffice: any) => {
  if (!addressOffice) {
    return;
  }

  const addresses: string[] = addressOffice
    .split('<br/>')
    .filter((item: string) => item);

  const pointOfficeList: Awaited<any>[] = await Promise.all(addresses.map(async (address: string, index: number) => {
    const currentCity = config.value.city.model.name || '';
    const currentAddress = address.replaceAll('&nbsp;', ' ')

    const response = await $api.agent.get(`/geocode-maps?geocode=${currentCity} ${currentAddress}`)
      .then((res) => {
        return res?._data
      })
      .catch((err) => {
        console.log('map-object-block error:', errorParserServerResponse(err));
      });

    if (!response) {
      return;
    }

    const GeoObject = response?.response?.GeoObjectCollection?.featureMember?.[0]?.GeoObject || null;
    if (!GeoObject) {
      return;
    }

    const [lat, lon]: [number, number] = (GeoObject?.Point?.pos || '').split(' ').reverse();

    return {
      id: `office-sales-${index}`,
      name: 'Офис продаж',
      subtitle: address,
      variant: 'primary',
      lat: lat,
      lon: lon,
      isOffice: true
    };
  }));
  officePoints.value = pointOfficeList;
};

const changeActiveProject = (project: { id: string | number }) => {
  activeProject.value = project;
};

watchEffect(() => {
  const addressOffice = vars?.value['Адрес офиса продаж']
  getSalesOfficePoint(addressOffice)
})

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.object-map__root {

}

.object-map__wrapper {
	background: var(--primary-10);
}

.object-map {
	padding: 40px;
	display: flex;
	gap: 40px;

	.title {
		color: var(--primary);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 130%; /* 31.2px */
		letter-spacing: -0.48px;
		margin-bottom: 20px;
	}
}

.object-map-right, .object-map-left {
	padding-right: 15px;
	width: 50%;
}

.object-map-left {
	display: flex;
	flex-direction: column;
}

.project-cards-scroll {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.project-cards {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.office-card {
	margin-bottom: 30px;
}

//.map-wrapper {
//  display: flex;
//  flex-direction: column;
//  justify-content: space-between;
//}
@include media('xl') {
	.object-map {
		padding: 40px 0;
		gap: 20px;
	}
}

@include media('md') {
	.object-map-right, .object-map-left {
		padding-right: 0;
	}

	.project-cards-scroll {
		height: fit-content;
	}

	.object-map__wrapper {
		border-radius: 16px 16px 0 0;
	}

	.object-map {
		flex-direction: column;
		padding: 24px 0;
	}

	.object-map-left {
		width: 100%;
	}

	.object-map-right {
		width: 100%;
	}

	.project-cards {
		gap: 8px;
		position: relative;
	}
}

@include media('sm') {
	.object-map {
		& .title {
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 130%;
		}
	}
}
</style>
